import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    meta: {
      redirect: false,
    },
    component: () => import('@/views/CallbackView.vue')
  },
  {
    path: '/documents',
    name: 'DocumentList',
    component: () => import('@/views/document/List.vue')
  },
  {
    path: '/documents/create',
    name: 'DocumentCreate',
    component: () => import('@/views/document/Create.vue')
  },
  {
    path: '/documents/view/:id',
    name: 'DocumentView',
    component: () => import('@/views/document/View.vue'),
    props: true,
  },
  {
    path: '/documents/archive',
    name: 'DocumentArchiveList',
    component: () => import('@/views/archive/DocumentList.vue')
  },
  {
    path: '/courses',
    name: 'CourseList',
    component: () => import('@/views/courses/List.vue')
  },
  {
    path: '/courses/create',
    name: 'CourseCreate',
    component: () => import('@/views/courses/Create.vue')
  },
  {
    path: '/courses/view/:id',
    name: 'CourseView',
    component: () => import('@/views/courses/View.vue'),
    props: true
  },
  {
    path: '/courses/archive',
    name: 'CourseArchiveList',
    component: () => import('@/views/archive/CourseList.vue')
  },
  {
    path: '/regulations/list',
    name: 'RegulationsList',
    component: () => import('@/views/regulations/List.vue')
  },
  {
    path: '/regulations/create',
    name: 'RegulationsCreate',
    component: () => import('@/views/regulations/Create.vue')
  },
  {
    path: '/regulations/view/:id',
    name: 'RegulationsView',
    component: () => import('@/views/regulations/View.vue'),
    props: true,
  },
  {
    path: '/options/:tab?',
    name: 'OptionsList',
    component: () => import('@/views/options/List.vue')
  },
  {
    path: '/options/view/:tab/:id',
    name: 'OptionView',
    component: () => import('@/views/options/View.vue')
  },
  {
    path: '/positions/view/:id',
    name: 'PositionView',
    component: () => import('@/views/options/Position.vue')
  },
  {
    path: '/options/create',
    name: 'OptionCreate',
    component: () => import('@/views/options/Create.vue')
  },
  {
    path: '/users/list',
    name: 'UserList',
    component: () => import('@/views/users/List.vue')
  },
  {
    path: '/users/create',
    name: 'UserCreate',
    component: () => import('@/views/users/Create.vue')
  },
  {
    path: '/users/view/:id',
    name: 'UserView',
    component: () => import('@/views/users/View.vue')
  },
  {
    path: '/users/archive',
    name: 'UserArchiveList',
    component: () => import('@/views/archive/UserList.vue')
  },
  {
    path: '/users/edit/:id',
    name: 'UserEdit',
    component: () => import('@/views/users/Edit.vue')
  },
  {
    path: '/roles/list',
    name: 'RoleList',
    component: () => import('@/views/roles/List.vue')
  },
  {
    path: '/roles/edit/:id',
    name: 'RoleView',
    component: () => import('@/views/roles/View.vue')
  },
  {
    path: '/roles/create',
    name: 'RoleCreate',
    component: () => import('@/views/roles/Create.vue')
  },
  {
    path: '/statistics',
    name: 'StatisticsList',
    component: () => import('@/views/statistics/List.vue')
  },
  {
    path: '/statistics/users',
    name: 'StatisticsUsersList',
    component: () => import('@/views/statistics/UserList.vue')
  },
  {
    path: '/events/create',
    name: 'EventsCreate',
    component: () => import('@/views/events/View.vue')
  },
  {
    path: '/events/view/:id',
    name: 'EventsView',
    component: () => import('@/views/events/View.vue')
  },
  {
    path: '/events/list',
    name: 'EventsList',
    component: () => import('@/views/events/List.vue')
  },
  {
    path: '/events/my',
    name: 'UserEventsList',
    component: () => import('@/views/events/UserList.vue')
  },
  {
    path: '/synchronizations/list',
    name: 'SynchronizationsList',
    component: () => import('@/views/synchronizations/List.vue')
  },
  {
    path: '/synchronizations/create',
    name: 'SynchronizationsCreate',
    component: () => import('@/views/synchronizations/Create.vue')
  },
  {
    path: '/synchronizations/view/:id',
    name: 'SynchronizationsView',
    component: () => import('@/views/synchronizations/View.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _from, next) => {
  if(to.name === 'Callback') {
    localStorage.clear()
    next();
  } else {
    store.dispatch('Auth/checkToken', to)
    next();
  }
});
export default router
